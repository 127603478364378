export enum PermissionsEnum {
    UsersUpdate = 'update-users',
    UsersDelete = 'delete-users',
    UsersAffil = 'affil-users', // Замещение пользователей (любых)
    UsersSelfAffil = 'self-affil-users', // Замещение пользователей (себя)

    CitiesSearch = 'search-cities',
    CitiesList = 'list-cities',
    CitiesCreate = 'create-cities',
    CitiesUpdate = 'update-cities',
    CitiesDelete = 'delete-cities',

    ProjectsSearch = 'search-projects',
    ProjectsList = 'list-projects',
    ProjectsUpdate = 'update-projects',
    ProjectsCreate = 'create-projects',
    ProjectsJson = 'json-projects',

    OrganisationsSearch = 'search-organisations',
    OrganisationsList = 'list-organisations',
    OrganisationsUpdate = 'update-organisations',
    OrganisationsCreate = 'create-organisations',
    OrganisationsDelete = 'delete-organisations',

    SectionsSearch = 'search-sections',
    SectionsList = 'list-sections',
    SectionsUpdate = 'update-sections',
    SectionsCreate = 'create-sections',
    SectionsDelete = 'delete-sections',

    FloorsSearch = 'search-floors',
    FloorsList = 'list-floors',
    FloorsUpdate = 'update-floors',
    FloorsCreate = 'create-floors',
    FloorsDelete = 'delete-floors',

    ApartmentsSearch = 'search-apartments',
    ApartmentsList = 'list-apartments',
    ApartmentsUpdate = 'update-apartments',
    ApartmentsCreate = 'create-apartments',
    ApartmentsDelete = 'delete-apartments',
    ApartmentsLoader = 'loader-apartments',

    ViolationsCreate = 'create-violations',

    DesignsSearch = 'search-designs', // Получение данных по дизайнам чертежей
    DesignsCreate = 'create-designs', // Создание дизайна чертежа
    DesignsChange = 'change-designs', // Изменения данных для дизайна чертежа
    DesignsChangeExecutors = 'change-design-executors', // Изменение данных исполнителей для дизайна чертежа
    DesignsDelete = 'delete-designs', // Удаление дизайна чертежа
    DesignsToWork = 'to-work-designs', // Перенос дизайнов чертежей в статус "В работе"
    DesignsToProduction = 'to-production-designs', // Перенос дизайнов чертежей в статус "В производство работ"
    DesignsAccept = 'accept-designs', // Перенос дизайна чертежа в статус "Принято"
    DesignsComplete = 'complete-designs', // Перенос дизайна чертежа в статус "Выполнено"
    DesignsCancel = 'cancel-designs', // Перенос дизайна чертежа в статус "Аннулировано"

    DesignsCreateTask = 'create-author-task-designs', // Создание задачи
    DesignsUpdateTask = 'update-author-task-designs', // Обновление задач
    DesignsAcceptTask = 'accept-author-task-designs', // Перенос задач в статус "Принято"
    DesignsCompleteTask = 'complete-author-task-designs', // Перенос задач в статус "Выполнено"

    DesignsCreateRemark = 'create-task-designs', // Создание замечания
    DesignsUpdateRemark = 'update-task-designs', // Обновление замечаний
    DesignsAcceptRemark = 'accept-task-designs', // Перенос замечаний в статус "Принято"
    DesignsChangeRemarkGroup = 'change-task-group-designs', // Изменение гуппы задачи

    DesignsGeneralCustomerManager = 'general-customer-manager-get-all-designs', // Региональный руководитель со стороны заказчика
    DesignsGeneralManager = 'general-general-designer-manager-get-all-designs', // Главный специалист со стороны проектной организации
    DesignRepresentativeOrg = 'general-representative-get-all-designs', // (Куратор) Представитель проектной организации по проектированию
    DesignRepresentativeCustomer = 'customer-representative-get-all-designs', // (Куратор) Представитель заказчика по проектированию

    EstimatesGet = 'get_estimates', // позволяет пользователю видеть документы по организации из подсистемы смет
    EstimatesGetAll = 'get_all_estimates', // позволяет пользователю видеть все документы подсистемы смет(Право для администратора)

    EstimatesCreate = 'create_estimates', // Создание доп.смет и актов
    EstimatesUpdate = 'update_estimates', // Изменение смет
    EstimatesDelete = 'delete_estimates', // Удаление доп. смет и актов
    EstimatesImport = 'import_estimates', // Импортировать данные из excel в доп.смету или акт
    EstimatesUpdateDate = 'update_date_estimates', // Изменение даты по документам

    EstimatesSendForReview = 'to_check_estimates', // Отправка доп.смет и актов на проверку && контрагент
    EstimatesSendForRevision = 'to_fixing_estimates', // Отправка доп.смет и актов на доработку && сметчик
    EstimatesSendForCancellation = 'cancel_estimates', // Отмена доп.смет и актов
    EstimatesSendForApproval = 'agree_estimates', // Согласование доп.смет и актов
    EstimatesSendForSync = 'sync_estimates', // Синхронизация смет, Отправка документа в 1с

    EstimatesSupervisor = 'supervisor_estimates', // права для технадзора
    EstimatesProjectManager = 'project_manager_estimates', // права для руководителя проекта
    EstimatesEstimator = 'estimator_estimates', // права для сметчик
    EstimatesContractor = 'contractor_estimates', // права для подрядчик

    // управление проектами
    PMAgreeBaseSchedule = 'agree_base_schedule_project_manager', // Согласование БПГ
    PMAgreeDetailedSchedule = 'agree_detailed_schedule_project_manager', // Согласование ДПГ
    PMAgreeDetailedScheduleExecution = 'agree_detailed_schedule_execution_project_manager', // Согласование ДПГ(исполнение)
    PMCopyChartVersion = 'copy_chart_version_project_manager', //  Копирование версии графика
    PMCreateBaseSchedule = 'create_base_schedule_project_manager', // Создание БПГ
    PMCreateChart = 'create_chart_project_manager', // Создание графика
    PMCreateChartVersion = 'create_chart_version_project_manager', // Создание версии графика
    PMCreateDetailedScheduleExecution = 'create_detailed_schedule_execution_project_manager', //  Создание ДПГ(исполнение)
    PMCreateDetailedSchedule = 'create_detailed_schedule_project_manager', // Создание ДПГ
    PMDeleteBaseSchedule = 'delete_base_schedule_project_manager', // Удаление БПГ
    PMDeleteChartVersion = 'delete_chart_version_project_manager', // Удаление версии графика
    PMDeleteDetailedScheduleExecution = 'delete_detailed_schedule_execution_project_manager', // Удаление ДПГ(исполнение)
    PMDeleteDetailedSchedule = 'delete_detailed_schedule_project_manager', // Удаление ДПГ
    PMGetCharts = 'get_charts_project_manager', // Получение данных по системе
    PMLoadBaseScheduleTasks = 'load_base_schedule_tasks_project_manager', // Формирование БПГ
    PMLoadDetailedScheduleExecutionTasks = 'load_detailed_schedule_execution_tasks_project_manager', // Формирование ДПГ(исполнение)
    PMLoadDetailedScheduleTasks = 'load_detailed_schedule_tasks_project_manager', // Формирование ДПГ
    PMNotAgreeBaseSchedule = 'not_agree_base_schedule_project_manager', // Отклонение согласования БПГ
    PMNotAgreeDetailedScheduleExecution = 'not_agree_detailed_schedule_execution_project_manager', // Отклонение согласования ДПГ(исполнение)
    PMNotAgreeDetailedSchedule = 'not_agree_detailed_schedule_project_manager', // Отклонение согласования ДПГ
    PMSendBaseScheduleApproval = 'send_base_schedule_to_approval_project_manager', // Отправка на согласование БПГ
    PMSendDetailedScheduleExecutionApproval = 'send_detailed_schedule_execution_to_approval_project_manager', // Отправка на согласование ДПГ(исполнение)
    PMSendDetailedScheduleApproval = 'send_detailed_schedule_to_approval_project_manager', // Отправка на согласование ДПГ
    PMSyncCharts = 'sync_charts_project_manager', // Синхронизация графиков с 1с pm
    PMTransferChartVersion = 'transfer_chart_version_project_manager', // Перенос данных из версии в версию графика
    PMUpdateBaseSchedule = 'update_base_schedule_project_manager', // Изменение БПГ
    PMUpdateChart = 'update_chart_project_manager', // Изменение графика
    PMUpdateChartVerstion = 'update_chart_version_project_manager', // Изменение версии графика
    PMUpdateDetailedScheduleExecution = 'update_detailed_schedule_execution_project_manager', // Изменение ДПГ(исполнение)
    PMUpdateDetailedScheduleExecutionTask = 'update_detailed_schedule_execution_task_project_manager', // Изменение задачи в ДПГ(исполнение)
    PMUpdateDetailedSchedule = 'update_detailed_schedule_project_manager', // Изменение ДПГ
}

export default PermissionsEnum;
